import React from "react";
/* UI components and Container */
import { Button as ButtonComponent } from "react-bootstrap";
//Import button types
import type { Text, Type, Name, Variant, Weight, Size, Outline, Block, Disabled, Children, ClickHandler} from "./types";
import "./button.scss";

interface ButtonProps {
    text?: Text,
    type?: Type,
    name?: Name,
    variant?: Variant,
    weight?: Weight,
    size?: Size,
    outline?: Outline,
    block?: Block,
    disabled?: Disabled,
    children?: Children,
    onClickHandler?: ClickHandler
}

/*
 * Button component
*/
const Button: React.FC<ButtonProps> = ({
        //default props
        text = "",
        type = "button",
        name = "",
        variant = "primary",
        weight = "500",
        size = "md",
        outline = false,
        disabled = false,
        block = false,
        onClickHandler = event => console.log("onClick event fired"),
        children
    }) => {

    //Action types
    const button_types = ["submit", "button", "reset"];
    const button_type = (button_types.includes(type) ? type : "button");
    //Variant type
    const variant_types = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
    const variant_type = (variant_types.includes(variant) ? variant : "primary");
    //Weight types
    const weight_types = ["200", "300", "400", "500", "600", "700", "800", "900"];
    const weight_type = (weight_types.includes(weight) ? weight : "500");

    return(
        <ButtonComponent variant={outline ? `outline-${variant_type}-${weight}` : `${variant_type}-${weight}`} onClick={ onClickHandler } disabled={disabled} name={name} type={button_type} size={size} block={block}>
            {text}
            {children}
        </ButtonComponent>
    );
}

export default Button;
