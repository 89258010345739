/**
  * @desc this function extracts a substring from a string based on a regex pattern
  * 
  * @param string, string value
  * @param regex, regex expression to match
  * @param as_string, if true return a string otherwise return and array
  * 
  * @return string
*/
export const getStringByRegex = (string:string, regex:RegExp, as_string:boolean = true) => {
  
  if (typeof(string) !== "string" || typeof(regex) !== "object") return null;

  let matched_string =  string.match(regex);

  if(matched_string !== null && as_string) return matched_string.pop();

  return matched_string;

}

/**
  * @desc this function returns the difference between two strings
  * 
  * @param string, first string value
  * @param string, second string value
  * 
  * @return str2 - str1 (as string)
*/
export const getDifferenceBetweenString = (str1:string, str2:string) => {
  
  if(typeof(str1) !== "string" || typeof(str2) !== "string") return null;

  let diff= "";
  
  str2.split("").forEach(function(val, i){
    if (val !== str1.charAt(i))
      diff += val ;         
  });

  return diff;

}

/**
    * @desc this function converts a string to title case format
    * 
    * @param string, string value
    * 
    * @return string
*/
export const toTitleCase = (string:string) => {
  
  if (!!string === false || typeof(string) !== "string") return null;

  return string.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );

}

/**
* @desc this function converts a string to lower case format
* 
* @param string, string value
* 
* @return string
*/
export const toLowerCase = (string:string) => {

  if (!!string === false || typeof(string) !== "string") return null;

  return string.toLowerCase();

}

/**
* @desc this function converts a string to upper case format
* 
* @param string, string value
* 
* @return string
*/
export const toUpperCase = (string:string) => {

  if (!!string === false || typeof(string) !== "string") return null;

  return string.toUpperCase();

}