
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PrivateComponent } from "./core/components/layout";
import { getCookie } from "./core/services";
import { REACT_APP_BASENAME, REACT_APP_THEME} from "./config";
import { routes } from "./routes";
import { store } from "./store";
import "./core/style/App.scss";

/**
 * Global App
*/

const App = () => {

	const REDIRECTION_URL = "/login";

	useEffect(() => {
		document.body.className = (REACT_APP_THEME !== undefined) ? REACT_APP_THEME : "light";
	}, []);
	
	return (
		<Provider store={store}>
			<Router basename={(REACT_APP_BASENAME !== undefined) ? `/${REACT_APP_BASENAME}` : "/"}>
				{routes.map((route:any, index:any) => {
					return (
						<React.Fragment key={index}>
							{/*1st level private component*/}
							{route.private && (
								<PrivateComponent 
									key={index} 
									exact={route.exact} 
									path={route.path} 
									redirectionUrl={REDIRECTION_URL}
									onRedirection={ () => getCookie("token") }
									component = {(props:any) => {
										return (
											<route.component {...props} />
										);
									}} 
								/> 
									
							)}
							{/*1st level public component*/}
							{!route.private && ( 
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									component = {(props:any) => {
										return (
											<route.component {...props} />
										);
									}}
								/>
							)}
							{/*2nd level pages */}
							{route.child !== undefined && (
									route.child.map((route_child:any, index_child:any) => {
										return (
											<React.Fragment key={index_child}>
												{/*2nd level private component*/}
												{route_child.private && (
													<PrivateComponent 
														key={index_child} 
														exact={route_child.exact} 
														path={route_child.path} 
														onRedirection={ () => getCookie("token") }
														redirectionUrl={REDIRECTION_URL}
														component = {(props:any) => {
															return (
																<route_child.component {...props} />
															);
														}} 
													/> 
												)}
												{/*2nd level public component*/}
												{!route_child.private && (
													<Route
														key={index_child}
														path={route_child.path}
														exact={route_child.exact}
														component = {(props:any) => {
															return (
																<route_child.component {...props} />
															);
														}}
													/>
												)}
												{/*3rd level pages */}
												{route_child.child !== undefined && (
													route_child.child.map((route_sub_child:any, index_sub_child:any) => {
														return (
															<React.Fragment key={index_sub_child}>
																{/*3rd level private component*/}
																{route_sub_child.private && (
																	<PrivateComponent 
																		key={index_sub_child} 
																		exact={route_sub_child.exact} 
																		path={route_sub_child.path} 
																		onRedirection={ () => getCookie("token") }
																		redirectionUrl={REDIRECTION_URL}
																		component = {(props:any) => {
																			return (
																				<route_sub_child.component {...props} />
																			);
																		}} 
																	/> 
																)}
																{/*3rd level public component*/}
																{!route_sub_child.private && (
																	<Route
																		key={index_sub_child}
																		path={route_sub_child.path}
																		exact={route_sub_child.exact}
																		component = {(props:any) => {
																			return (
																				<route_sub_child.component {...props} />
																			);
																		}}
																	/>
																)}
															</React.Fragment>
														)
													})	
												)}
											</React.Fragment>
										);
									})
								)}
						</React.Fragment>
					);
				})}
			</Router>
		</Provider>
	);
	
}
  
export default App;