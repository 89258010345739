import React from "react";
/* UI components and Container */
import Container from "react-bootstrap/Container";
//Import content types
import type { CustomClass, Fluid, Children } from "./types";

interface ContentProps {
    customClass?: CustomClass,
    fluid?: Fluid,
    children?: Children
}

/*
 * Content component
*/
const Content: React.FC<ContentProps> = ({
        //default props
        customClass = "",
        fluid = true,
        children
    }) => {

    return (
        <Container className={"content " + customClass} fluid={fluid}>
                {children}
        </Container>
    );
      
}

export default Content;
