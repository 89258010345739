import React from "react";
import {Spinner} from "react-bootstrap";
//Import alert types
import type { Animation, Size, Type, Variant } from "./types";
import "./loader.scss";

interface LoaderProps {
    variant?: Variant,
    animation?: Animation,
    size?: Size,
    type?: Type
}

/*
 * Loader component
*/
const Loader: React.FC<LoaderProps> = ({
        //default props
        animation = "grow",
        size = "sm",
        type = "relative",
        variant = "primary"
    }) => {

    //Loader type
    const position_types = ["relative", "absolute"];
    const position_type = (position_types.includes(type) ? type : "relative");
    //Animation types
    const animation_types = ["grow", "border"];
    const animation_type = (animation_types.includes(animation) ? animation : "grow");
    //Variant type
    const variant_types = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
    const variant_type = (variant_types.includes(variant) ? variant : "primary");

    return(
        <div className={"loader-" + position_type}>
            <Spinner animation={animation_type} size={size} variant={variant_type}/>
        </div>
    );
}

export default Loader;
