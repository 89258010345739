import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//Import header types
import { Placement, Text, Id, Children } from "./types";
import "./tooltip.scss";

interface TooltipProps {
    id: Id,
    text: Text,
    placement?: Placement,
    children?: Children
}

/*
 * Tooltip component
*/
const TooltipComponent: React.FC<TooltipProps> = ({id, text, placement, children}) => {

    const variant_placement = ["top", "bottom", "left", "right"];
    const place = (variant_placement.includes(placement) ? placement : "right");

    return (
        <OverlayTrigger
            placement={place}
            delay={{ show: 250, hide: 400 }}
            overlay={ <Tooltip id={id}>
                        {text}
                      </Tooltip>
                    }
            >
            {children}
        </OverlayTrigger>
    );
}

export default TooltipComponent;
