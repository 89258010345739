import momentz from "moment-timezone";
import moment from "moment";
import { getDifferenceBetweenString } from "../services";
import {REACT_APP_TIMEZONE } from "../../config";

/**
  * @desc this function converts a timestamp to a date with a specific format passed as input. 
  * if no timezone is specified, the function converts the timestamp to UTC standard.
  * 
  * @param timestamp, timestamp date
  * @param format, format conversion
  * @param utc, response date format, true -> utc, false -> no utc
  * 
  * @return converted date
*/
export const timestampToDate = (timestamp:number|Date, format:string, utc:boolean = false) => {

    if(typeof(timestamp) !== "number" || typeof(format) !== "string" || typeof(utc) !== "boolean") return null;
    if(timestamp.toString().length < 10) return null;
    
    let parsed_timestamp = parseTimestamp(timestamp),
        microsecond_date = getDifferenceBetweenString(parsed_timestamp as any as string, timestamp as any as string),
        parsed_date = null;

    if(REACT_APP_TIMEZONE !== undefined) {
        parsed_date = (utc) ? moment(parsed_timestamp).utc(false).format(format) : momentz(parsed_timestamp).tz(REACT_APP_TIMEZONE).format(format);
    }

    if(REACT_APP_TIMEZONE === undefined) {
        parsed_date = moment(parsed_timestamp).utc().format(format);
    }

    if(format.includes("SSS") && timestamp.toString().length > 10 && microsecond_date !== null) return parsed_date + microsecond_date;

    return parsed_date;

}

/**
  * @desc this function converts a date to a timestamp 
  * if no timezone is specified, the function converts the date to UTC standard.
  * 
  * @param date, date value
  * @param format, format conversion
  * @param utc, response date format, true -> utc, false -> no utc
  * 
  * @return timestamp number
*/
export const dateToTimestamp = (date:string, format:string = "YYYY-MM-DD HH:mm:ss", utc:boolean = false) => {
  
    if (typeof(date) !== "string" || typeof(format) !== "string" || typeof(utc) !== "boolean") return null;
  
    if(!moment(date, format).isValid()) return null;
  
    let parsed_date = null;
  
    if(REACT_APP_TIMEZONE !== undefined) {
      parsed_date = (utc) ? moment.utc(moment(date).utc().format(format)).valueOf() : momentz.tz(date, format, "UTC").valueOf();
    }
    
    if(REACT_APP_TIMEZONE === undefined) {
      parsed_date = moment.utc(moment(date).utc().format(format)).valueOf();
    }
  
  
    return parsed_date;
  
}

/**
  * @desc this function takes a timestamp as input and returns it in a millisecond format
  * 
  * @param timestamp, timestamp date
  * 
  * @return timestamp in millisecond format
*/
export const parseTimestamp = (timestamp:number) => {

    if (typeof(timestamp) !== "number") return null;

    //Millisecond date as 0
    if(timestamp.toString().length === 10) return timestamp*1000;
    //Millisecond date
    if(timestamp.toString().length === 13) return timestamp;
    //Microsecond date
    if(timestamp.toString().length > 10 && timestamp.toString().length !== 13) {
        let date_as_string = timestamp.toString();
        return parseInt(date_as_string.substring(0, 13));
    }
  
}
  

/**
  * @desc this function converts a time (in seconds) to HH:MM:SS format. 
  * 
  * @param value, time in seconds
  * 
  * @return time in HH:MM:SS format
*/
export const secondToHms = (value:number) => {

  if(typeof(value) !== "number") return null;

  let hours:number|string   = Math.floor(value / 3600),
      minutes:number|string = Math.floor((value - (hours * 3600)) / 60),
      seconds:number|string = value - (hours * 3600) - (minutes * 60); 

  if (hours   < 10) {hours   = `0${hours}`}
  if (minutes < 10) {minutes = `0${minutes}`}
  if (seconds < 10) {seconds = `0${seconds}`}

  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS

}


/**
  * @desc this function converts a time (in HH:MM:SS format) to second format. 
  * 
  * @param value, time in HH:MM:SS format
  * 
  * @return time in seconds
*/
export const hmsToSeconds = (value:string) => {

  if(typeof(value) !== "string") return null;

  const [hours, minutes, seconds] = value.split(":");
  let result = (seconds === undefined) ? (hours as any as number) * 60 * 60 + (minutes as any as number) * 60 : (hours as any as number) * 60 * 60 + (minutes as any as number) * 60 + (seconds as any as number) * 1;
  
  return result;
  
}

/**
  * @desc this function checks if a date is contained in a range of dates. 
  * 
  * @param date, date to check
  * @param start, start o time range
  * @param end, end of time range
  * @param format, date format, default hh:mm:ss
  * @param utc, response date format, true -> utc, false -> no utc
  * 
  * @return true if date is contained, false otherwise
*/
export const dateIsBetween = (date:number, start:string, end:string, utc:boolean = false, format:string = "HH:mm:ss") => {

	if(typeof(date) !== "number" || typeof(start) !== "string" || typeof(end) !== "string" || typeof(utc) !== "boolean" || typeof(format) !== "string") return null;
	
  let formatted_date = null;

  if(REACT_APP_TIMEZONE !== undefined) {
    formatted_date = (utc) ? moment(date).utc(false).format(format) : moment(date).tz(REACT_APP_TIMEZONE).format(format);
  }
  
  if(REACT_APP_TIMEZONE === undefined) {
    formatted_date = moment(date).utc().format(format);
  }

  let time = moment(formatted_date, format),
      before_time = moment(start, format),
      after_time = moment(end, format);

  return time.isBetween(before_time, after_time);

}


/**
  * @desc this function checks if a date is contained in the weekend range. 
  * @param date, date to check
  * @param utc, response date format, true -> utc, false -> no utc
  * @return true if date is contained, false otherwise
*/
export const dateIsInWeekend = (date:number, utc:boolean = false) => {

  if(typeof(date) !== "number" || typeof(utc) !== "boolean") return null;

  let formatted_day = null;

  if(REACT_APP_TIMEZONE !== undefined) {
    formatted_day = (utc) ? moment(date).utc(false).format("dddd") : moment(date).tz(REACT_APP_TIMEZONE).format("dddd");
  }
  
  if(REACT_APP_TIMEZONE === undefined) {
    formatted_day = moment(date).utc().format("dddd");
  }
  
  return  formatted_day === "Sunday" || formatted_day === "Saturday";

}

/**
 * 
 * INFLUX DB GROUPING TIME: CHECK THESE FUNCTIONS
 * 
  * @desc this function calculates the difference between two dates
  * 
  * @param {string} start_date, start date value
  * @param {string} end_date, end date value
  * @param {string} type, time difference value (days, seconds, months)
  * 
  * @return number
export const getDateDifference = (start_date, end_date, type) => {

  if (start_date === undefined || end_date === undefined || type === undefined || (type !== "days" && type !== "seconds" && type !== "months")) return null;
  let timezone = (REACT_APP_TIMEZONE === undefined) ? "Europe/Rome" : REACT_APP_TIMEZONE;
  
  return(Math.abs(moment.duration(moment(start_date).tz(timezone).diff(moment(end_date).tz(timezone)))._data[type]))
};



* @desc this function calculates the grouping time for querying an influx db
* @param {number} interval, difference between two dates
* @return value of groupoing

export const getGroupingTime = interval => {

  if(interval === undefined || isNaN(interval)) return null;

  let duration = null;

  switch (true) {
      case (interval < 1):
        duration = "5s";
        break;
      case (interval < 3):
        duration = "10s";
        break;
      case (interval < 7):
        duration = "30s";
        break;
      case (interval < 30):
          duration = "3m";
        break;
      case (interval < 60):
          duration = "5m";
        break;
      case (interval > 90):
          duration = "30m";
        break;  
      default:
        duration = "5s";
    }

    return duration;
}
  
*/