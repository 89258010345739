//import React from "react";
import { toast, Flip, Zoom, Slide, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.scss";

toast.configure({});

const TOAST_LIST = new Set();
const MAX_TOAST_NUMBER = 3;


const Toast = (variant:string = "", text:string = "", animation = "") => {

    //Variant type
    const variant_types = ["INFO", "SUCCESS", "ERROR", "WARNING"];
    const variant_type = (variant_types.includes(variant.toUpperCase()) ? variant.toUpperCase() : "INFO");

    const switchIcon = (variant:any) => {
      switch(variant) {
        case "SUCCESS":
          return "fas fa-thumbs-up";
        case "ERROR":
          return "fas fa-fire";
        case "WARNING":
          return "fas fa-exclamation-circle";  
        default:
          return "fas fa-info-circle";
      }
    }

    const switchAnimation = (animation:any) => {
      switch(animation) {
        case "flip":
          return Flip;
        case "slide":
          return Slide;
        case "bounce":
          return Bounce; 
        case "zoom":
          return Zoom; 
        default:
          return Flip;
      }
    }

    if (TOAST_LIST.size < MAX_TOAST_NUMBER) {

        let toast_id:any = null;

        if(variant_type === "SUCCESS") {
          toast_id = toast.success(<div><i className={switchIcon(variant_type)}></i> {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        if(variant_type === "INFO") {
          toast_id = toast.info(<div><i className={switchIcon(variant_type)}></i> {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        if(variant_type === "WARNING") {
          toast_id = toast.warn(<div><i className={switchIcon(variant_type)}></i> {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        if(variant_type === "ERROR") {
          toast_id = toast.error(<div><i className={switchIcon(variant_type)}></i> {text}</div>, {
            transition: switchAnimation(animation),
            onClose: () => TOAST_LIST.delete(toast_id)
          });
        }

        TOAST_LIST.add(toast_id);
    }

}

export default Toast;
