import  styles from '../../../style/global/global.module.scss';

const STROKE = styles.boundingBoxColor;
const FILL = 'rgba(255, 255, 255, 0.0)';

export const CIRCLE = {
  radius: 20,
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE
}

export const RECTANGLE = {
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
  width: 40,
  height: 40,
  angle: 0
}

export const TRIANGLE = {
  width: 40, 
  height: 40, 
  fill: FILL,
  stroke: STROKE,
  left: 100,
  top: 100
}