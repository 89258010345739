import React from "react";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { Content } from "../../layout";
import { Icon } from "ts-react-feather-icons";
//Import header types
import { Pathname, Routes, HeaderComponent } from "./types";
import "./header.scss";

interface HeaderProps {
    pathname: Pathname,
    routes: Routes,
    header?: HeaderComponent
}

/*
 * Header component
*/
const Header: React.FC<HeaderProps> = ({
    pathname,
    routes,
    header
  }) => {

  const flatJson = (into:any, json:any):any => {

      if(json === undefined) return into;
      if(Array.isArray(json)) return json.reduce(flatJson, into);
      into.push(json);
      return flatJson(into, json.child);
  }

  const getPageTitle = (path:string) => {

    let flatten_routes = flatJson([], routes);

    const { value: VALUE } = flatten_routes
                              .filter((route:any) => pathname === route.path)
                              .pop();

    return VALUE;

  }
 
	return (
      <header className="header">
        <Content>
          <Row>
            <Col>
              <Navbar expand="md" variant="light">
                <Navbar.Text>
                  {getPageTitle(pathname)}
                </Navbar.Text>
                <Nav variant="pills" className="mr-auto">
                  {header}
                </Nav>
                <Nav>
                  <Nav.Link href="#" onClick={(e:any) => console.log(e)}>
                    <span className="user-info">
                      <span className="user-circle">
                        <Icon name="user" size={18} />
                      </span>
                        test@test.it
                      <span className="user-signout">
                        <Icon name="log-out" size={14} />
                      </span>
                    </span>
                  </Nav.Link>
                </Nav> 
              </Navbar>
            </Col>
          </Row>
        </Content>
      </header>
  );

}

export default Header;