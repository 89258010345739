import React from "react";
import { Modal } from "react-bootstrap";
//Import panel types
import { Title, Visible, Size, EnforceFocus, Children, OnHideHandler } from "./types";
import "./modal.scss";

interface ModalProps {
    title?: Title,
    visible?: Visible,
    size?: Size,
    enforceFocus?: EnforceFocus,
    children?: Children,
    onHideHandler?: OnHideHandler
}

//const ModalComponent = (props) => {

/*
 * Modal component
*/
const ModalComponent: React.FC<ModalProps> = ({
        //default props
        title = "",
        size = "sm",
        visible =  false,
        enforceFocus = false,
        onHideHandler = () => console.log("Hide modal event"),
        children
    }) => {

    return (
        <Modal show={visible} size={size} onHide={onHideHandler} enforceFocus={false}>
            {title != "" &&
                <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            }
            
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default ModalComponent;
