import React from "react";
import { Row, Col } from "react-bootstrap";
import { Content } from "../../layout";
import "./breadcrumb.scss";

/*
 * Breadcrumb component
*/
 const Breadcrumb: React.FC = () => {

    const URL = window.location.pathname.split("/").slice(1);

	return (
        <Content>
            <Row>
                <Col>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><i className="fa fa-solid fa-desktop" /></li> 
                                {URL.map((route, index) => {
                                    return (
                                        <li key={index} className="breadcrumb-item"> <span>{route}</span> </li> 
                                    );
                                })}
                        </ol>
                    </nav>
                </Col>
            </Row>
        </Content>
    );

}

export default Breadcrumb;
