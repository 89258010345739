import React from "react";
import {
	ProSidebar,
	Menu,
	MenuItem,
	SubMenu,
	SidebarHeader,
	SidebarFooter,
	SidebarContent,
} from "react-pro-sidebar";
import { Image, Nav } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import logo from "../../../../assets/images/logo.png";
import { REACT_APP_BASENAME} from "../../../../config";
import { getStringByRegex } from "../../../services";
import { Icon } from "ts-react-feather-icons";
//Import sidebar types
import { Pathname, Routes } from "./types";
import "./sidebar.scss";

interface SidebarProps {
    pathname: Pathname,
    routes: Routes
}

/*
 * Sidebar component
*/
const Sidebar: React.FC<SidebarProps> = ({
        pathname,
        routes
    }) => {

	return (
        <ProSidebar
            collapsed={true}
            toggled={false}
            breakPoint="xs"
            width={230}
        >
            <SidebarHeader
                style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    letterSpacing: "2px",
                    padding: "10px",
                    textAlign: "center"
                }}
            >
                <Image
                    alt="Princess"
                    src={logo}
                    width="auto"
                    height="32"
                    className="d-inline-block align-center"
                /> 
            </SidebarHeader>
            
            <SidebarContent>
                <Scrollbars style={{ height: "calc(100vh - 120px)" }} autoHide={true}>
                    <Menu>
                        <MenuItem 
                            icon={<Icon name="home" size={22} />}
                        >
                            <Nav.Link href={(REACT_APP_BASENAME !== undefined) ? `/${REACT_APP_BASENAME}/` : "/"} >
                                Home
                            </Nav.Link>
                        </MenuItem>
                        <MenuItem 
                            icon={<Icon name="user" size={22} />}
                        >
                            User
                        </MenuItem>
                        <MenuItem 
                            icon={<Icon name="settings" size={22} />}
                        >
                            Settings
                        </MenuItem>
                    </Menu>
                    <Menu>
                        {routes.map((route:any, index:number) => {
                            if(!route.exludeFromNavigation) {
                                if(route.child !== undefined) {
                                    return (
                                            <SubMenu 
                                                icon={<Icon name={route.icon} size={22} />}
                                                title={route.value}
                                                key={"1level-child" + index}
                                                className={(getStringByRegex(pathname, new RegExp(`^${route.path}`, "g")) !== null) ? "active" : ""}
                                            >
                                            {route.child.map((route_child:any, index_child:number) => {
                                                if(!route_child.exludeFromNavigation) {
                                                    if(route_child.child !== undefined) {
                                                        return (
                                                            <SubMenu 
                                                                title={route_child.value}
                                                                key={"2-level-child" + index_child}
                                                                className={(getStringByRegex(pathname, new RegExp(`^${route_child.path}`, "g")) !== null) ? "active" : ""}
                                                            >
                                                                {route_child.child.map((route_child_sub:any, index_child_sub:number) => {
                                                                    if(!route_child_sub.exludeFromNavigation) {
                                                                        return(
                                                                            <MenuItem 
                                                                                key={"3-level-child" + index_child_sub}
                                                                              
                                                                                active={(getStringByRegex(pathname, new RegExp(`^${route_child_sub.path}`, "g")) !== null) ? true : false}
                                                                            >
                                                                                <Nav.Link href={(REACT_APP_BASENAME !== undefined) ? `/${REACT_APP_BASENAME}${route_child_sub.path}` : route_child_sub.path} >
                                                                                    {route_child_sub.value}
                                                                                </Nav.Link>
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                            </SubMenu>
                                                        );
                                                    }
                                                    if(route_child.child === undefined) {
                                                        return (
                                                            <MenuItem 
                                                                key={"2-level-child" + index_child}
                                                                active={(getStringByRegex(pathname, new RegExp(`^${route_child.path}`, "g")) !== null) ? true : false}
                                                            >
                                                                <Nav.Link href={(REACT_APP_BASENAME !== undefined) ? `/${REACT_APP_BASENAME}${route_child.path}` : route_child.path} >
                                                                    {route_child.value}
                                                                </Nav.Link>
                                                            </MenuItem>
                                                        );
                                                    }
                                                }
                                            })}
                                            </SubMenu>
                                    );
                                }
                                if(route.child === undefined) {
                                    return (
                                        <MenuItem 
                                            key={"1-level-child" + index}
                                            active={(getStringByRegex(pathname, new RegExp(`^${route.path}`, "g")) !== null) ? true : false}
                                            icon={<Icon name={route.icon} size={22} />}
                                        >
                                            <Nav.Link href={(REACT_APP_BASENAME !== undefined) ? `/${REACT_APP_BASENAME}${route.path}` : route.path} >
                                                {route.value}
                                            </Nav.Link>
                                        </MenuItem>
                                    );
                                }
                            }
                        })}
                    </Menu>
                </Scrollbars>
            </SidebarContent>
        </ProSidebar>
	);
}

export default Sidebar;