import React from "react";
import { Route, Redirect } from "react-router-dom";
//Import private components types
import { Component, Exact, Path, RedirectionUrl, OnRedirection } from "./types";

interface PrivateRouteProps {
    component: Component,
    exact: Exact,
    path: Path,
    redirectionUrl: RedirectionUrl,
    onRedirection: OnRedirection
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
        //default props
        component: ComponentItem,
        exact,
        path,
        redirectionUrl,
        onRedirection
    }) => {

    return(
        <Route
            path={path}
            exact={exact}
            render={props => (
                onRedirection() !== null
                    ? <ComponentItem {...props} />
                    : <Redirect to={{ pathname: redirectionUrl, state: { from: props.location } }} />
            )} 
        />    
    );
}

export default PrivateRoute;
