import React, {useState} from "react";
import { DropdownButton, Dropdown, Image } from "react-bootstrap";
//import dropdown types
import { Variant, Title, Header, Size, Items, Active, OnClickHandler} from "./types";
/* importing styles */
import "./dropdown.scss";

interface DropdownProps {
    variant?: Variant,
    title?: Title,
    header?: Header,
    items?: Items,
    size?: Size,
    active?: Active,
    onClickHandler?: OnClickHandler
}

/*
 * Dropdown component
*/
const DropdownContainer: React.FC<DropdownProps> = ({
        //default props
        variant = "outline-secondary",
        size = "md",
        title = "",
        header = "",
        items = [],
        active = false,
        onClickHandler = event => console.log("onClick event fired!")
    }) => {

    const [activeItem, setActive] = useState(0);
    const variant_types = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
    const type = (variant_types.includes(variant) ? variant : "outline-secondary");
    
    return(
        <DropdownButton variant={type} title={title} size={size}>
            { header !== "" &&
                <React.Fragment>
                    <Dropdown.Header>{header}</Dropdown.Header>
                    <Dropdown.Divider />
                </React.Fragment>
            }
            {items.map((item:any, index:number) => (
                <Dropdown.Item key={index}  onClick={e => {onClickHandler(e); setActive(index)}} data-custom={item.value} className={(index === activeItem && active) ? "active" : ""}>
                    {(item.image !== undefined) &&
                        <Image src={item.image} width="30" height="30" roundedCircle />
                    }
                    {item.label}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}

export default DropdownContainer;
