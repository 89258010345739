/**
 * A proper footer (made by two rows)
 */

 import React from "react";
 import { Content } from "../../layout";
 import { Row, Col, Image } from "react-bootstrap/";
 import moment from "moment";
 import logo from "../../../images/princess-sm.png";
 import "./footer.scss";
  
 /*
  * Footer component
 */
 const Footer: React.FC = () => {     
  
     const year = moment().year();
 
     return (
         <footer className="footer">
            <Content>
                <Row>
                    <Col lg={9} md={12} xs={12}>
                        <p className="copy-info">{year} © Copyrights 
                            <a href="https://www.kode-solutions.net/" target="_blank" rel="noreferrer">
                            Kode s.r.l. 
                            </a>
                        </p>
                    </Col>
                    <Col lg={3} md={12} xs={12}>
                        <p className="princess-info">Powered by Princess
                            <a href="https://www.kode-solutions.net/princess/" target="_blank" rel="noreferrer">
                                <Image
                                    alt="Princess"
                                    src={logo}
                                    width="15"
                                    height="15"
                                    className="d-inline-block align-top"
                                />
                            </a>
                        </p>
                    </Col>
                </Row>
            </Content>
         </footer>
     );
 }
  
 export default Footer;
  
 