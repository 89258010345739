import React from "react";
import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { Loader } from "../core/components/layout";
import { default as routes_data } from "./routes.json";
import { REACT_APP_MENU_ALIGN } from "../config";


/**
 * @desc component to render while the code is being loaded
*/
const Loading = () => <Loader type="absolute" animation="border" />

/**
 * @desc maps route data from json to react router format
*/
const generateRouteData = (route:any) => {

    let component = Loadable({
        loader: () => import(`../views${route.path}/${route.component}Container`),
        loading: Loading
    });

    return {
        path: route.path,
        exact: route.exact,
        private: route.private,
        exludeFromNavigation: route.exludeFromNavigation,
        component: typeof(route.component) === "string" ? component : () => <Redirect to={route.component.redirect} />,
        icon: route.icon,
        value: route.value
    };
    
}

interface RouteProps {
    path: string,
    exact: boolean,
    private: boolean,
    exludeFromNavigation: boolean,
    component: string | any,
    child?: Array<RouteProps>,
    value?: string,
    icon?: string
}

export const ROUTES:Array<any> = [];

for (let route of routes_data) {
    
    const ROUTE:RouteProps = route;
    const { child: CHILD } = ROUTE;
    //Get first level route data
    let route_data: any = generateRouteData(ROUTE),
        child_data: Array<any> = [];

    if(CHILD !== undefined) {

        for (let route_child of CHILD) {

            const ROUTE_CHILD:RouteProps = route_child;
            const { child: SUB_CHILD } = ROUTE_CHILD;
            //Get second level route data
            let route_child_data:any = generateRouteData(ROUTE_CHILD),
                child_sub_data: Array<any> = [];

            if(SUB_CHILD !== undefined) {

                for (const ROUTE_CHILD_SUB of SUB_CHILD) {

                    let route_child_sub_data:any = generateRouteData(ROUTE_CHILD_SUB);
                    //Third Level
                    child_sub_data.push(route_child_sub_data);

                }

                route_child_data.child = child_sub_data;

            }

            //Second Level
            child_data.push(route_child_data);

        }

        route_data.child = child_data;
    }
    
    //First Level 
    ROUTES.push(route_data);

}